import Head from 'next/head';
import Header from './header';
import Footer from './footer';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { AdminHeader } from './admin/admin-header';
import HeaderPst from './pst/header-pst';
import AdminFooter from './admin/admin-footer';
import { useRouter } from 'next/router';
import HeaderBasic from './header-basic';
import { ROLES } from '../shared/constants';
import { UserContext } from './user-provider';
import FooterPst from './footer-pst';
import Modal from './base/modal';
import Image from 'next/image';

import Logo from '../public/images/loading-logo.svg';
import Script from 'next/script';

type LayoutProps = {
  children: any;
  className?: string;
  headerType?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  showAuthOptionsOnHeader?: boolean;
  type?: string;
  changeEmail?: string;
  bottonScroll?: boolean;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  className = null,
  headerType = null,
  hideFooter = false,
  hideHeader = false,
  showAuthOptionsOnHeader = true,
  type = null,
  changeEmail,
  bottonScroll,
}) => {
  const router = useRouter();

  const [pageType, setPageType] = useState(null);
  const { user, modalUserRemoved, setModal } = useContext(UserContext);

  useEffect(() => {
    const getPageType = () => {
      const pathBasePart = router.pathname
        .split('/')
        .filter((pathPart) => pathPart.length > 0)[0];
      if (pathBasePart) {
        if (pathBasePart === 'admin') setPageType(ROLES.ADMIN);
        if (pathBasePart === 'negocios') setPageType(ROLES.PST);
        if (pathBasePart !== 'admin' && pathBasePart !== 'negocios') {
          if (user) {
            setPageType(ROLES.TOURIST);
          } else {
            setPageType(ROLES.NO_LOGGED);
          }
        }
      } else {
        setPageType(ROLES.NO_LOGGED);
      }
    };

    getPageType();
  }, [user]);

  const getHeader = () => {
    if (hideHeader) return;

    if (pageType === ROLES.ADMIN || pageType === ROLES.AUTORITY_TOURIST)
      return <AdminHeader changeEmail={changeEmail} />;

    if (pageType === ROLES.PST) return <HeaderPst pageType={pageType} />;

    if (headerType === 'basic') {
      return (
        <HeaderBasic
          role="banner"
          showLoginButton={showAuthOptionsOnHeader ?? true}
        />
      );
    }

    return <Header pageType={pageType} />;
  };

  const getFooter = () => {
    if (hideFooter) return;
    if (pageType === ROLES.ADMIN) return <AdminFooter />;
    if (pageType === ROLES.PST) return <FooterPst />;

    return <Footer />;
  };

  const handleClose = () => {
    setModal(false);
    router.reload();
  };

  return (
    <>
      <header>{getHeader()}</header>
      <main>
        <Head>
          <link
            href="https://fonts.googleapis.com/css2?family=Quicksand:wght@100;200;300;400;500;600&display=swap"
            rel="stylesheet"
          />

          <link rel="icon" href="/favicon.ico" />
          {type === 'admin' && <meta name="robots" content="noindex" />}
        </Head>

        <div id="layout-children" className={className}>
          {children}
          <Modal
            active={modalUserRemoved}
            onClose={handleClose}
            title={'Usuario eliminado'}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={Logo} height="100px" width="100px" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p style={{ textAlign: 'center', width: '80%' }}>
                Lamentamos decirle que esta cuenta fue eliminada, para poder
                ingresar tiene que crear una nueva cuenta con un correo
                diferente
              </p>
            </div>
          </Modal>
        </div>

        {getFooter()}
      </main>
    </>
  );
};

export default Layout;
